<template>
  <div>
    <div class="d-flex">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ clientData.id == 0 ? "ADD" : "EDIT" }} CLIENT Account
      </h3>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-2 mt-sm-3">
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                :name="`Account Number`"
                rules="required"
              >
                <b-form-group
                  label="Account Number"
                  :label-for="`account_number`"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    :id="`account_number`"
                    v-model="clientData.account_number"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter Account Number"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" sm="12" lg="6">
              <b-form-group
                label="Account Type"
                label-for="account_type_id"
                label-cols-md="3"
              >
                <div class="form-col-select">
                  <v-select
                    id="account_type_id"
                    background-color="white"
                    v-model="clientData.account_types"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="accountTypeOptions"
                    :clearable="false"
                    label="name"
                    multiple
                    :reduce="(option) => option.id"
                    placeholder="Select account type"
                  />
                  <feather-icon size="18" icon="ChevronDownIcon" />
                </div>
              </b-form-group>
            </b-col>
            <hr />

            <b-col cols="12" sm="4" lg="2">
              <b-form-group label="" label-for="is_company">
                <div class="form-col-select">
                  <v-select
                    id="is_company"
                    v-model="clientData.is_company"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clientOptions"
                    :clearable="false"
                    label="name"
                    :reduce="(option) => option.value"
                    placeholder="Select client type"
                  />
                  <feather-icon size="18" icon="ChevronDownIcon" />
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="8" lg="5">
              <validation-provider
                #default="validationContext"
                name="Name(EN)"
                rules="required"
              >
                <b-form-group
                  :label="`${
                    (clientData.is_company || clientData.is_company === 0) &&
                    clientOptions.find(
                      (item) => item.value == clientData.is_company
                    ).name
                  } (EN)`"
                  label-for="name_en"
                  label-cols-sm="3"
                  class="required"
                >
                  <b-form-input
                    id="name_en"
                    v-model="clientData.name_en"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter English name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="5">
              <!-- <validation-provider
                #default="validationContext"
                name="Name (CN)"
                rules="required"
              > -->
              <b-form-group
                :label="`${
                  (clientData.is_company || clientData.is_company === 0) &&
                  clientOptions.find(
                    (item) => item.value == clientData.is_company
                  ).name
                } (CN)`"
                label-for="name_cn"
                label-cols-sm="3"
              >
                <!-- class="required" -->
                <b-form-input
                  id="name_cn"
                  v-model="clientData.name_cn"
                  placeholder="Enter Chinese name"
                />
                <!-- :state="getValidationState(validationContext)" -->
                <!-- <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback> -->
              </b-form-group>
              <!-- </validation-provider> -->
            </b-col>
            <hr />
          </b-row>
          <div v-if="clientData.is_company" class="mt-1 mb-1">
            <div class="d-flex">
              <h4 class="mb-1 color4">Controlling Person</h4>
            </div>
            <b-row
              v-for="(person, index) in clientData.controlling_persons"
              :key="`controlling_person_${index}`"
              class="pb-2"
            >
              <b-col cols="12">
                <div class="d-flex border rounded">
                  <b-row class="flex-grow-1 p-2">
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="`Name(${index + 1})`"
                        rules="required"
                      >
                        <b-form-group
                          label="Name"
                          :label-for="`controlling_person_name_${index + 1}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <b-form-input
                            :id="`controlling_person_name_${index + 1}`"
                            v-model="person.name"
                            :state="getValidationState(validationContext)"
                            placeholder="Enter name"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="`Email(${index + 1})`"
                        rules="required|email"
                      >
                        <b-form-group
                          label="Email"
                          :label-for="`controlling_person_email_${index + 1}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <b-form-input
                            :id="`controlling_person_email_${index + 1}`"
                            v-model="person.email"
                            :state="getValidationState(validationContext)"
                            placeholder="Enter email"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="`Director/Shareholder(${index + 1})`"
                        rules="required"
                      >
                        <b-form-group
                          label="Director/Shareholder"
                          :label-for="`director_or_shareholder_${index + 1}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <div class="form-col-select">
                            <v-select
                              :id="`director_or_shareholder_${index + 1}`"
                              v-model="person.person_type_id"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="personTypeOptions"
                              :clearable="true"
                              label="type"
                              :reduce="(option) => option.id"
                              multiple
                            />

                            <feather-icon size="18" icon="ChevronDownIcon" />
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!--identity_document-->
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="`ID / Passport No.(${index + 1})`"
                        rules="required"
                      >
                        <b-form-group
                          label="ID / Passport No."
                          :label-for="`controlling_person_id_${index + 1}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <b-form-input
                            :id="`controlling_person_id_${index + 1}`"
                            v-model="person.identity_document"
                            :state="getValidationState(validationContext)"
                            placeholder="Enter ID / Passport No."
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="`Date of birth(${index + 1})`"
                        rules="required"
                      >
                        <b-form-group
                          label="Date of birth"
                          :label-for="`controlling_person_date_of_birth_${
                            index + 1
                          }`"
                          label-cols-md="3"
                          class="required"
                        >
                          <flat-pickr
                            v-model="person.date_of_birth"
                            class="form-control"
                            :config="flatDateConfigForBirth"
                            placeholder="Enter date of birth"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="`Passport Expiry(${index + 1})`"
                        rules=""
                      >
                        <b-form-group
                          label="Passport Expiry"
                          :label-for="`controlling_person_passport_expiry_${
                            index + 1
                          }`"
                          label-cols-md="3"
                          class=""
                        >
                          <flat-pickr
                            v-model="person.passport_expiry"
                            class="form-control"
                            :config="flatDateConfig"
                            placeholder="Enter Passport Expiry"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="`Birthday Reminder(${index + 1})`"
                        rules="required"
                      >
                        <b-form-group
                          label="Birthday Reminder"
                          :label-for="`birthday_reminder_bit_${index + 1}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <b-form-checkbox
                            :id="`birthday_reminder_bit_${index + 1}`"
                            :checked="person.birthday_reminder_bit"
                            switch
                            inline
                            v-model="person.birthday_reminder_bit"
                            value="1"
                            style="margin-top: 5px"
                          >
                            {{
                              person.birthday_reminder_bit
                                ? "Enable"
                                : "Disable"
                            }}
                          </b-form-checkbox>

                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <div
                    class="d-flex flex-column justify-content-between border-left py-50 px-25"
                  >
                    <feather-icon
                      size="16"
                      icon="PlusIcon"
                      class="cursor-pointer text-success"
                      @click="addControllingPerson"
                    />

                    <feather-icon
                      size="16"
                      icon="XIcon"
                      class="cursor-pointer text-danger"
                      @click="removeControllingPersons(index, person)"
                      v-if="
                        clientData &&
                        clientData.controlling_persons &&
                        clientData.controlling_persons.length > 1
                      "
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" class="">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addControllingPerson"
                >
                  <feather-icon
                    size="16"
                    icon="PlusIcon"
                    class="cursor-pointer mr-1"
                    @click="addItem"
                  />
                  Add Controlling Person
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div v-if="!clientData.is_company" class="mt-1 mb-1">
            <div class="d-flex">
              <h4 class="mb-1 color4">Client Details</h4>
            </div>
            <b-row
              v-for="(person, index) in clientData.client_details"
              :key="`client_detail_${index}`"
              class="pb-2"
            >
              <b-col cols="12">
                <div class="d-flex border rounded">
                  <b-row class="flex-grow-1 p-2">
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="`Name(${index + 1})`"
                        rules="required"
                      >
                        <b-form-group
                          label="Name"
                          :label-for="`client_detail_name_${index + 1}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <b-form-input
                            :id="`client_detail_name_${index + 1}`"
                            v-model="person.name"
                            :state="getValidationState(validationContext)"
                            placeholder="Enter name"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="`Email(${index + 1})`"
                        rules="required|email"
                      >
                        <b-form-group
                          label="Email"
                          :label-for="`client_detail_email_${index + 1}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <b-form-input
                            :id="`client_detail_email_${index + 1}`"
                            v-model="person.email"
                            :state="getValidationState(validationContext)"
                            placeholder="Enter email"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!--identity_document-->
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="`ID / Passport No.(${index + 1})`"
                        rules="required"
                      >
                        <b-form-group
                          label="ID / Passport No."
                          :label-for="`client_detail_id_${index + 1}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <b-form-input
                            :id="`client_detail_id_${index + 1}`"
                            v-model="person.identity_document"
                            :state="getValidationState(validationContext)"
                            placeholder="Enter ID / Passport No."
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="`Date of birth(${index + 1})`"
                        rules="required"
                      >
                        <b-form-group
                          label="Date of birth"
                          :label-for="`client_detail_date_of_birth_${
                            index + 1
                          }`"
                          label-cols-md="3"
                          class="required"
                        >
                          <flat-pickr
                            v-model="person.date_of_birth"
                            class="form-control"
                            :config="flatDateConfigForBirth"
                            placeholder="Enter date of birth"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="`Passport Expiry(${index + 1})`"
                        rules=""
                      >
                        <b-form-group
                          label="Passport Expiry"
                          :label-for="`client_detail_passport_expiry_${
                            index + 1
                          }`"
                          label-cols-md="3"
                          class=""
                        >
                          <flat-pickr
                            v-model="person.passport_expiry"
                            class="form-control"
                            :config="flatDateConfig"
                            placeholder="Enter Passport Expiry"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <div
                    class="d-flex flex-column justify-content-between border-left py-50 px-25"
                  >
                    <feather-icon
                      size="16"
                      icon="PlusIcon"
                      class="cursor-pointer text-success"
                      @click="addClientDetails"
                    />

                    <feather-icon
                      size="16"
                      icon="XIcon"
                      class="cursor-pointer text-danger"
                      @click="removeClientDetails(index, person)"
                      v-if="
                        clientData &&
                        clientData.client_details &&
                        clientData.client_details.length > 1
                      "
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" class="">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addClientDetails"
                >
                  <feather-icon
                    size="16"
                    icon="PlusIcon"
                    class="cursor-pointer mr-1"
                    @click="addItem"
                  />
                  Add Client Details
                </b-button>
              </b-col>
            </b-row>
          </div>

          <b-row>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                :name="`Account Status`"
                rules="required"
              >
                <b-form-group
                  label="Account Status"
                  label-for="account_status_id"
                  label-cols-md="3"
                  class="required"
                >
                  <div class="form-col-select">
                    <v-select
                      id="account_status_id"
                      v-model="clientData.account_status_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="accountStatusOptions"
                      :clearable="false"
                      label="status_name"
                      :reduce="(option) => option.id"
                      placeholder="Select account status"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                :name="`Principal`"
                rules="required"
              >
                <b-form-group
                  label="Principal"
                  label-for="employer_company_id"
                  label-cols-md="3"
                  class="required"
                >
                  <div class="form-col-select">
                    <v-select
                      id="employer_company_id"
                      v-model="clientData.employer_company_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="employerCompanyOptions"
                      :clearable="false"
                      label="company_name"
                      :reduce="(option) => option.id"
                      placeholder="Select Principal"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Email Address"
                rules="required|email"
              >
                <b-form-group
                  label="Email Address"
                  label-for="email"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="email"
                    v-model="clientData.email"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter email address"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr />

            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Address"
                rules="required"
              >
                <b-form-group
                  label="Address 1"
                  label-for="address"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="address"
                    v-model="clientData.address"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter address"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <validation-provider #default="validationContext" name="Address">
                <b-form-group
                  label="Address 2"
                  label-for="address_2"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="address_2"
                    v-model="clientData.address_2"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter address"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider #default="validationContext" name="Address">
                <b-form-group
                  label="Address 3"
                  label-for="address_3"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="address_3"
                    v-model="clientData.address_3"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter address"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr />

            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="ID / Passport No. / BR No."
                rules="required"
              >
                <b-form-group
                  label="ID / Passport No. / BR No."
                  label-for="hkid"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="hkid"
                    v-model="clientData.hkid"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter ID, passport no., or BR no."
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- <b-col cols="12" md="6">
              <validation-provider #default="validationContext" name="Passport Expiry" rules="">
                <b-form-group label="Passport Expiry" label-for="passport_expiry_date" label-cols-md="3" class="">
                  <flat-pickr v-model="clientData.passport_expiry_date" class="form-control"
                    :config="flatDateConfig" placeholder="Enter date" />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col> -->
            <b-col cols="12" md="6" v-if="clientData.is_company">
              <validation-provider
                #default="validationContext"
                :name="
                  !clientData.is_company
                    ? 'Date of Birth'
                    : 'Date of Incorporation'
                "
                rules="required"
              >
                <b-form-group
                  :label="
                    !clientData.is_company
                      ? 'Date of Birth'
                      : 'Date of Incorporation'
                  "
                  label-for="date_of_birth"
                  label-cols-md="3"
                  class="required"
                >
                  <flat-pickr
                    v-model="clientData.date_of_birth"
                    class="form-control"
                    :config="flatDateConfig"
                    placeholder="Enter date"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6" v-else> </b-col>
            <hr />

            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Account Opening Date"
                rules="required"
              >
                <b-form-group
                  label="Account Opening Date"
                  label-for="open_date"
                  label-cols-md="3"
                  class="required"
                >
                  <flat-pickr
                    v-model="clientData.open_date"
                    class="form-control"
                    :config="flatDateConfig"
                    placeholder="Select Account Opening Date"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6">
              <validation-provider
                #default="validationContext"
                name="Authorized Users"
                rules="required"
              >
                <b-form-group
                  label="Authorized Users"
                  label-for="authorized_users"
                  label-cols-md="3"
                  class="required"
                >
                  <v-select
                    id="authorized_users"
                    v-model="clientData.authorized_persons"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userOptions"
                    :clearable="false"
                    label="name"
                    multiple
                    :reduce="(option) => option.id"
                    placeholder="Select authorized users"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr />
            <b-col cols="12" md="6">
              <!-- <validation-provider
                #default="validationContext"
                name="PI Expiry Date"
                rules="required"
              > -->
              <b-form-group
                label="PI Expiry Date"
                label-for="pi_expiration_date"
                label-cols-md="3"
              >
                <!-- class="required" -->
                <flat-pickr
                  v-model="clientData.pi_expiration_date"
                  class="form-control"
                  :config="flatDateConfig"
                />
                <!-- <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback> -->
              </b-form-group>
              <!-- </validation-provider> -->
            </b-col>
            <b-col cols="12" md="6">
              <!-- <validation-provider
                #default="validationContext"
                name="Standing Authority Expiry Date"
                rules="required"
              > -->
              <b-form-group
                :label="
                  clientData.employer_company_id > 1
                    ? 'Discretionary Renewal Date'
                    : 'Standing Authority Expiry Date'
                "
                label-for="authority_expiration_date"
                label-cols-md="3"
              >
                <!-- class="required" -->
                <flat-pickr
                  v-model="clientData.authority_expiration_date"
                  class="form-control"
                  :config="flatDateConfig"
                />
                <!-- <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback> -->
              </b-form-group>
              <!-- </validation-provider> -->
            </b-col>
            <hr />

            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Contact Number"
                rules="required"
              >
                <b-form-group
                  label="Contact Number"
                  label-for="contact_number"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="contact_number"
                    v-model="clientData.contact_number"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter contact number"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Fax"
                label-for="fax"
                label-cols-md="3"
                class=""
              >
                <b-form-input
                  id="fax"
                  v-model="clientData.fax"
                  placeholder="Enter fax number"
                />
              </b-form-group>
            </b-col>
            <hr />

            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Relationship Manager"
                rules="required"
              >
                <b-form-group
                  label="Relationship Manager"
                  label-for="relationship_manager_id"
                  label-cols-md="3"
                  class="required"
                >
                  <div class="form-col-select">
                    <v-select
                      id="relationship_manager_id"
                      v-model="clientData.relationship_manager_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="userOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Select relationship manager"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Remark"
                label-for="remarks"
                label-cols-md="3"
              >
                <b-form-input
                  id="remarks"
                  v-model="clientData.remarks"
                  placeholder="Enter interested program"
                />
              </b-form-group>
            </b-col>
            <hr />
          </b-row>
          <div
            class="position-relative"
            v-for="(bank, index) in clientData.banks"
            :key="`custodian_bank_${index}`"
          >
            <b-row>
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :name="`custodian_bank_${index}`"
                  rules="required"
                >
                  <b-form-group
                    :label="
                      index > 0 ? `Custodian Bank (${index})` : 'Custodian Bank'
                    "
                    :label-for="`bank_${index}`"
                    label-cols-md="3"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="bank.bank_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="bankOptions"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        placeholder="Select Custodian Bank"
                      />

                      <feather-icon size="18" icon="ChevronDownIcon" />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Bank Account No."
                  rules="required"
                >
                  <b-form-group
                    :label="
                      index > 0
                        ? `Bank Account No. (${index})`
                        : 'Bank Account No.'
                    "
                    label-for="account_number"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      id="account_number"
                      v-model="bank.account_number"
                      :state="getValidationState(validationContext)"
                      placeholder="Enter account number"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <hr />
            </b-row>
            <a
              v-if="index == 0"
              href="#"
              class="curr-btn addCurr"
              @click.prevent.stop="addItem"
            >
              <feather-icon size="24" icon="PlusCircleIcon"
            /></a>
            <a
              v-else
              href="#"
              class="curr-btn reduceCurr"
              @click.prevent.stop="removeItem(index, bank)"
            >
              <feather-icon size="24" icon="MinusCircleIcon"
            /></a>
          </div>

          <b-row>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Client Birthday Reminder"
                rules="required"
              >
                <b-form-group
                  label="Client Birthday Reminder"
                  label-for="birthday_reminder_bit"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-checkbox
                    :checked="clientData.birthday_reminder_bit"
                    switch
                    inline
                    v-model="clientData.birthday_reminder_bit"
                    value="1"
                    style="margin-top: 5px"
                  >
                    {{
                      clientData.birthday_reminder_bit ? "Enable" : "Disable"
                    }}
                  </b-form-checkbox>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6" v-if="clientData.employer_company_id == 1">
              <b-form-group
                label="FATCA Expiry Date"
                label-for="fatca_expiration_date"
                label-cols-md="3"
                class="required"
              >
                <text-slider
                  type="annual"
                  :isOverCurr="true"
                  :year-filter.sync="yearFilter"
                ></text-slider>
              </b-form-group>
            </b-col>
            <hr />

            <!-- <b-col v-if="clientData.id > 0" cols="12" md="6"> </b-col> -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required"
              >
                <b-form-group
                  label="Email"
                  label-for="mail_by_email"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-checkbox
                    :checked="clientData.mail_by_email"
                    switch
                    inline
                    v-model="clientData.mail_by_email"
                    value="1"
                    style="margin-top: 5px"
                  >
                    {{ clientData.mail_by_email ? "Enable" : "Disable" }}
                  </b-form-checkbox>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="clientData.id > 0" cols="12" md="6">
              <b-form-group
                label="Approval Status"
                label-for="is_approved"
                label-cols-md="3"
                class=""
              >
                <div class="form-col-select">
                  <v-select
                    id="is_approved"
                    v-model="clientData.is_approved"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="approveOptions"
                    :clearable="false"
                    label="name"
                    :reduce="(option) => option.value"
                    placeholder="Select Approval Status"
                  />
                  <feather-icon size="18" icon="ChevronDownIcon" />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import textSlider from "@/components/slider/textSlider.vue";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  data() {
    return {
      required,
      alphaNum,
      email,
      flatDateConfigForBirth1: {
        altInput: true,
        altFormat: "Y-m-d",
        allowInput: true,
        dateFormat: "Y-m-d",
        maxDate: "today",
      },
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    textSlider,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  watch: {
    clientData(newItem, oldItem) {
      return newItem.fatca_expiration_date
        ? (this.yearFilter = newItem.fatca_expiration_date)
        : (this.yearFilter = new Date().getFullYear());
    },
  },
  methods: {
    test() {
      console.log("click");
    },
    hide() {
      this.$router.replace("/clients");
    },
    onSubmit() {
      this.clientData.birthday_reminder_bit = this.clientData
        .birthday_reminder_bit
        ? this.clientData.birthday_reminder_bit
        : 0;
      if (this.clientData.employer_company_id == 1) {
        this.clientData.fatca_expiration_date = this.yearFilter;
      } else {
        this.clientData.fatca_expiration_date = null;
      }
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          if (this.clientData.is_company == 0) {
            this.clientData.controlling_persons = null;
          } else {
            this.clientData.client_details = null;
          }
          store
            .dispatch("app-client/addClient", this.clientData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.$router.push(`/clients`);
              if (this.clientData.id == 0) {
                // Return to client list
                //this.hide();
                //	sp1.hk.cmbintl.com
                this.clientData.id = response.data.id;
                this.redirect(response.data.id);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    addControllingPerson() {
      this.clientData.controlling_persons.push({
        name: "",
        email: "",
        identity_document: "",
        date_of_birth: null,
        person_type_id: null,
        birthday_reminder_bit: false,
      });
    },
    addClientDetails() {
      this.clientData.client_details.push({
        name: "",
        email: "",
        identity_document: "",
        date_of_birth: null,
      });
    },
    removeClientDetails(index, item) {
      // check number of record
      let record =
        this.clientData &&
        this.clientData.client_details &&
        this.clientData.client_details.length;
      if (record <= 1) {
        this.makeToast(
          "danger",
          "Warning",
          "Please submit at least one client record."
        );
      } else {
        if (item.id > 0) {
          this.$swal({
            title: "Are you sure?",
            text: "Your action is final and you will not be able to retrieve the client reecord.",
            showCancelButton: true,
            confirmButtonText: "Delete",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.$store
                .dispatch("app-client/deleteClientDetail", { id: item.id })
                .then((response) => {
                  this.$swal({
                    text: response.data.message,
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                  this.$emit("refetch-data");
                })
                .catch((error) => {
                  this.$swal({
                    text: JSON.stringify(error.response.data.message),
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                });
            }
          });
        } else {
          this.clientData.client_details.splice(index, 1);
        }
      }
    },
    removeControllingPersons(index, item) {
      // check number of record
      let record =
        this.clientData &&
        this.clientData.controlling_persons &&
        this.clientData.controlling_persons.length;
      if (record <= 1) {
        this.makeToast(
          "danger",
          "Warning",
          "Please submit at least one controlling person record."
        );
      } else {
        if (item.id > 0) {
          this.$swal({
            title: "Are you sure?",
            text: "Your action is final and you will not be able to retrieve the controlling person.",
            showCancelButton: true,
            confirmButtonText: "Delete",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.$store
                .dispatch("app-client/deleteControllingPerson", { id: item.id })
                .then((response) => {
                  this.$swal({
                    text: response.data.message,
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                  this.$emit("refetch-data");
                })
                .catch((error) => {
                  this.$swal({
                    text: JSON.stringify(error.response.data.message),
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                });
            }
          });
        } else {
          this.clientData.controlling_persons.splice(index, 1);
        }
      }
    },
    addItem() {
      this.clientData.banks.push(
        JSON.parse(JSON.stringify({ id: 0, bank_id: null, account_number: "" }))
      );
    },
    removeItem(index, item) {
      // check number of record
      let record =
        this.clientData &&
        this.clientData.banks &&
        this.clientData.banks.length;
      if (record <= 1) {
        this.makeToast(
          "danger",
          "Warning",
          "Please submit at least one bank record."
        );
      } else {
        if (item.id > 0) {
          this.$swal({
            title: "Are you sure?",
            text: "Your action is final and you will not be able to retrieve the custodian bank record.",
            showCancelButton: true,
            confirmButtonText: "Delete",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.$store
                .dispatch("app-client/deleteBank", { id: item.id })
                .then((response) => {
                  this.$swal({
                    text: response.data.message,
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                  this.$emit("refetch-data");
                })
                .catch((error) => {
                  this.$swal({
                    text: JSON.stringify(error.response.data.message),
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                });
            }
          });
        } else {
          this.clientData.banks.splice(index, 1);
        }
      }
    },
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    accountTypeOptions: {
      type: Array,
      required: true,
    },
    accountStatusOptions: {
      type: Array,
      required: true,
    },
    employerCompanyOptions: {
      type: Array,
      required: true,
    },
    bankOptions: {
      type: Array,
      required: true,
    },
    personTypeOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const resetClientData = () => {
      props.clientData = JSON.parse(JSON.stringify({ id: 0 }));
    };

    const loading = ref(false);

    const clientOptions = [
      { value: 0, name: "Natural Person" }, // Change from Client to Natural Person 20230306
      { value: 1, name: "Legal Entity" }, // Change from Company to Legal Entity 20230306
    ];

    const approveOptions = [
      { value: 0, name: "Not Approved" },
      { value: 1, name: "Approved" },
    ];

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetClientData);

    const yearFilter = ref(new Date().getFullYear());

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      clientOptions,
      approveOptions,
      loading,
      yearFilter,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
hr {
  width: calc(100% - 1.5rem);
  margin-top: 0px;
}

input::placeholder {
  color: lightgrey !important;
}

@media screen and (max-width: 767px) {
  .curr-btn {
    top: 51%;
  }
}
</style>
