<template>
  <div class="d-flex slider-btn-group">
    <b-button @click="move(-1)"
      ><feather-icon icon="ChevronLeftIcon" class="" size="16"
    /></b-button>
      <div class="slides">
        <div class="slide-item">
          <p v-if="type == 'annual'">
            {{this.yearFilter}}
          </p>
          <p v-if="type == 'biAnnual'">
            {{this.quarterFilter > 2 ? this.yearFilter + ' Q3 - Q4' : this.yearFilter + ' Q1 - Q2'}}   
          </p>
          <p v-if="type == 'quarter'">
            {{this.yearFilter + ', Q' + this.quarterFilter}}
          </p>
        </div>
      </div>
    <b-button @click="move(1)"
      ><feather-icon icon="ChevronRightIcon" class="" size="16"
    /></b-button>
  </div>
</template> 
<script>
import { BButton } from "bootstrap-vue";
export default {
  props: {
    type: {
      type: String,
      requried: true,
    },
    isOverCurr:{
      type:Boolean
    },
    yearFilter: {
      type: Number,
      required: true,
    },
    quarterFilter:{
      type: Number,
      // required: true
    }
  },
  data() {
    return {
      data: {
      },
    };
  },
  computed: {
  
  },
  components: {
    BButton,
  },
  methods: {
    move(amount) {

        switch (this.type) {
            case "annual":
              if(this.isOverCurr){
                this.$emit('update:year-filter', this.yearFilter + amount )
              }else if( !(this.yearFilter + amount > new Date().getFullYear())){
                this.$emit('update:year-filter', this.yearFilter + amount )
              }
              break;
            case "biAnnual":
                if(this.quarterFilter + (amount * 2) < 1)
                {
                  this.$emit('update:year-filter', this.yearFilter - 1)
                  this.$emit('update:quarter-filter', this.quarterFilter + (amount * 2) + 4)
                }else if(this.quarterFilter + (amount * 2) > 4)
                {
                  if(!(this.yearFilter + amount > new Date().getFullYear())){
                    this.$emit('update:year-filter', this.yearFilter + 1)
                    this.$emit('update:quarter-filter', this.quarterFilter + (amount * 2) - 4)
                  }
                }else{
                  this.$emit('update:quarter-filter', this.quarterFilter + (amount * 2))
                }
              break;
            case "quarter":
                if(this.quarterFilter + (amount * 1) < 1)
                {
                  this.$emit('update:year-filter', this.yearFilter - 1)
                  this.$emit('update:quarter-filter', this.quarterFilter + (amount * 1)+4)
                }else if(this.quarterFilter + (amount * 1) > 4)
                {
                  if(!(this.yearFilter + amount > new Date().getFullYear())){
                    this.$emit('update:year-filter', this.yearFilter + 1)
                    this.$emit('update:quarter-filter', this.quarterFilter + (amount * 1)-4)
                  }
                }else{
                  this.$emit('update:quarter-filter', this.quarterFilter + (amount * 1))
                }  
        }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.slider-btn-group {
  align-items: center;
  justify-content: space-between;
  width: 220px;
  .slide-item {
    p {
      color: $primary-color-1;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
  .btn {
    margin: 0 1rem;
    height: 28px;
    width: 28px;
    padding: 0px;
    background-color: $primary-color-5 !important;
    color: $primary-color-4 !important;
    border-color: transparent !important;
  }
}

.slides {
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  span {
    display: flex;
    position: relative;
    justify-content: center;
  }
  .animated {
    transition: all 400ms ease;
    position: absolute;
  }

  .slide-in,
  .slide-out-active {
    opacity: 0;
    transform: translate(0, -50%);
  }

  .slide-out {
    opacity: 1;
  }
}
</style>