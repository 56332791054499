<template>
  <component :is="clientData === undefined ? 'div' : 'b-card'">
    <b-alert variant="danger" :show="clientData === undefined">
      <h4 class="alert-heading">Error fetching client data</h4>
      <div class="alert-body">
        No client found with this client id. Check
        <b-link class="alert-link" :to="{ name: 'clients-list' }"> Client List </b-link>
        for other clients.
      </div>
    </b-alert>
    <client-edit-tab-information
      :client-data="clientData"
      :user-options="userOptions"
      :account-type-options="accountTypeOptions"
      :account-status-options="accountStatusOptions"
      :employer-company-options="employerCompanyOptions"
      :bank-options="bankOptions"
      :person-type-options="personTypeOptions"
      @refetch-data="refetchData"
      class="m-sm-2"
    />
  </component>
</template>

<script>
import { BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import clientStoreModule from "../clientStoreModule";
import ClientEditTabInformation from "./ClientEditTabInformation.vue";

export default {
  components: {
    ClientEditTabInformation,
    BCard,
    BAlert,
    BLink,
  },
  setup() {
    const clientData = ref({});

    const CLIENT_APP_STORE_MODULE_NAME = "app-client";
    const today = new Date();
    const year = today.getFullYear();

    const client = {
      id: 0,
      account_number: "",
      name_cn: "",
      name_en: "",
      birthday_reminder_bit: 1,
      mail_by_email: 1,
      fatca_expiration_date: year,
      account_type_id: [],
      is_company: 0,
      banks: [{ id: 0, bank_id: null, account_number: "" }],
      controlling_persons: [
        {
          name: "",
          email: "",
          identity_document: "",
          date_of_birth: null,
          person_type_id: [],
          send_birthday_email: 0,
          passport_expiry: null,
          birthday_reminder_bit: false,
        },
      ],
      client_details: [
        {
          name: "",
          email: "",
          identity_document: "",
          date_of_birth: null,
          passport_expiry: null,
        },
      ],
    };

    const demoClient = {
      id: 0,
      name_cn: "PAPER ONE Ltd",
      name_en: "PAPER ONE Ltd",
      account_type_id: [],
      is_company: 0,
      account_status_id: parseInt(1),
      address_1: "Flat A, 10/F",
      address_2: "Tsuen King Wai Building",
      address_3: "Tsuen Wan, KLN",
      open_date: "2022-10-06",
      authorized_persons: [2, 5],
      date_of_birth: "1980-11-25",
      pi_expiration_date: "2023-10-06",
      contact_number: "26612345",
      relationship_manager_id: 2,
      remarks: "SI",
      email: "jebb.wong@jvsakk.com",
      hkid: "Z003352(A)",
      account_types: [1, 2, 3, 4, 5, 6, 7],
      banks: [{ id: 0, bank_id: 3, account_number: "0127121009668" }],
      controlling_persons: [
        {
          name: "Mary Chan",
          email: "jebb.wong@jvsakk.com",
          identity_document: "Z003352(A)",
          date_of_birth: "1980-11-25",
          person_type_id: 1,
          // birthday_reminder_bit: 0,
        },
      ],
    };

    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "clients-create") {
        console.log("client", client);
        clientData.value = client;
      } else {
        store
          .dispatch("app-client/fetchClient", { id: router.currentRoute.params.id })
          .then((response) => {
            clientData.value = response.data.client;
          })
          .catch((error) => {
            console.log("error when fetching client", error);
            if (error.response.status === 404) {
              clientData.value = undefined;
            }
          });
      }
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });

    const accountTypeOptions = ref([]);
    const personTypeOptions = ref([]);
    const accountStatusOptions = ref([]);
    const employerCompanyOptions = ref([]);
    const userOptions = ref([]);
    const bankOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          account_type: true,
          account_status: true,
          person_type: true,
          user: true,
          bank: true,
          employer_company: true,
        })
        .then((response) => {
          accountTypeOptions.value = response.data.account_types;
          personTypeOptions.value = response.data.person_types;
          accountStatusOptions.value = response.data.account_statuses;
          employerCompanyOptions.value = response.data.employer_companies;
          userOptions.value = response.data.users;
          bankOptions.value = response.data.banks;
        });
    };

    return {
      clientData,
      personTypeOptions,
      accountTypeOptions,
      accountStatusOptions,
      employerCompanyOptions,
      userOptions,
      bankOptions,
      refetchData,
      refetchOption,
    };
  },
};
</script>
